import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded',function() {

    window.partners_cooperation = function() {
        const el = document.querySelector('#partners');
    
        const init = ()=> {
            
            const show_cooperation = el.querySelectorAll('.js-show_cooperationProjects'),
                  hide_cooperation = document.querySelectorAll('.js-hide_cooperationProjects'),
                  carousel = el.querySelector('.js-partnersCarousel'),
                  cooperation = document.querySelector('.js-cooperation'),
                  cooperation_caousel = cooperation.querySelectorAll('.c-subsitecarousel');
            
            for (let i = 0; i < show_cooperation.length; i++) {
                show_cooperation[i].addEventListener('click', (e)=> {
                    carousel.classList.toggle('is-hidden');
                    
                    if (e.currentTarget.dataset.projects) {
                        document.getElementById(e.currentTarget.dataset.projects).classList.toggle('is-hidden');
                        window.projectsCarousel();
                    }    
                    e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
                })
            }
    
            for (let i = 0; i < hide_cooperation.length; i++) {
                hide_cooperation[i].addEventListener('click', (e)=> {
                    carousel.classList.remove('is-hidden');
    
                    for (let j = 0; j < cooperation_caousel.length; j++) {
                        if (!cooperation_caousel[j].classList.contains('is-hidden')) {
                            cooperation_caousel[j].classList.add('is-hidden');
                        }
                    }
                    e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
                })
            }

            setTimeout(()=> {
                const queryString = window.location.search;
                if (queryString) {
                    const urlParams = new URLSearchParams(queryString),
                          target = urlParams.get('c');
    
                    if (target != null) {
                        carousel.classList.toggle('is-hidden');
                        document.getElementById(target).classList.toggle('is-hidden');
                        
                        let offsetTop = document.querySelector('.js-cooperation').offsetTop,
                            topbar_height = document.querySelector('.js-top').clientHeight;
                                                                            
                        scroll({
                            top: offsetTop-topbar_height,
                            behavior: "smooth"
                        })
                        
                        window.projectsCarousel();
                    }
                }                
            }, 100);            
        }

        el ? init() : false;
    }
    
}, false);

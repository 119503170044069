import maplibregl from 'maplibre-gl';
require('../../node_modules/maplibre-gl/dist/maplibre-gl.css');
const key = 'EjKosYvVjfZunqGN18J5';
document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementById('map');

    const init = function() {
        console.log('init map')
        const map = new maplibregl.Map({
            container: el, // container id
            style: `https://api.maptiler.com/maps/d8fa1eef-77ba-4294-a927-536251522843/style.json?key=${key}`, // style URL
            center: [
                21.000610,
                52.223130
            ], // starting position [lng, lat]
            zoom: 15, // starting zoom
        });
        map.addControl(new maplibregl.NavigationControl(), 'bottom-right');

        const icon = document.createElement('img');
        icon.className = 'marker';
        icon.src = "img/assets/logo-uszka--purple.svg";

        new maplibregl.Marker({element: icon})
            .setLngLat([21.000610, 52.223130])
            .addTo(map);
    }

    el ? init() : false;

});

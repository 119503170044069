document.addEventListener('DOMContentLoaded',function() {

    const filters = document.getElementById('filters');
    
    const init = function() {
        
        const tag = document.getElementsByClassName('js-tag'),
              filterItem = document.getElementsByClassName('js-filterItem'),
              //reset = document.getElementsByClassName('js-resetFilters')[0],
              grid = document.querySelector('#grid'),
              inColumns = document.querySelector('.c-projectsgrid');

        let grid_default__arr = [], tagsArray = [], new_grid;
        
        let mediaQuery = '(max-width: 640px)',
            mediaQueryList = window.matchMedia(mediaQuery);
        
        const setDefaultGrid = function() {
            for (let i = 0; i < filterItem.length; i ++) {
                grid_default__arr.push(filterItem[i]);            
            }
        }
        
        const compareTagsWithItems = function() {

            new_grid = document.createElement('div');

            
            // Compare active tags with items and append to new grid            
                       
            function containsClass(element, index, array) {
                
                for (let j = 0; j < grid_default__arr.length; j ++) {
                 
                    if ( (grid_default__arr[j].classList.contains(element)) ) {

                        new_grid.appendChild(grid_default__arr[j]);
                    }                    
                }
                
                return (element);
            };
            
            tagsArray.every(containsClass);            
        };

        const newGrid = function(itemsPerRow) {
            grid.innerHTML = '';
            
            let el = new_grid.querySelectorAll('.js-filterItem');
           
            // If empty, then back to default (Reset)
             
            if (el.length === 0) {
                for (let i = 0; i < grid_default__arr.length; i ++) {
                    new_grid.appendChild(grid_default__arr[i]);
                }
                
                el = new_grid.querySelectorAll('.js-filterItem');
            }


            // Add Fade Out effect
            
            for (let i = 0; i < el.length; i ++) {
                el[i].classList.add('fade-out');
            }
            
            //alert(itemsPerRow)

            if (inColumns) {
                //alert('s')
                // Create Columns
                for (let a = 0; a < itemsPerRow; a++) {

                    var wrapper = document.createElement('div');
                    wrapper.setAttribute('class', 'c-projectsgrid__col');

                    grid.appendChild(wrapper);
                }

                let col = document.getElementsByClassName('c-projectsgrid__col');


                // Insert items to columns

                let col_idx = 0;

                el.forEach((item) => {

                    col[col_idx].appendChild(item);

                    setTimeout(function () {
                        item.classList.remove('fade-out');
                    }, 1);

                    col_idx++;

                    if (col_idx < itemsPerRow) {
                    } else {
                        col_idx = 0;
                    }
                });
            } else {
                let wrapper = document.createElement('div');
                wrapper.classList.add('c-writting__items');
                wrapper.id = 'filteritems';
                grid.appendChild(wrapper);

                el.forEach((item) => {
                    wrapper.appendChild(item);
                    setTimeout(function () {
                        item.classList.remove('fade-out');
                    }, 1);
                });
            }
        };

        const getActiveTags = function() {
            
            tagsArray = [];
            
            for (let i = 0; i < tag.length; i++) {
            
            if (tag[i].checked === true) {
                    tagsArray.push(tag[i].value);
                }                
            }     
        };


        // Click on tag Action        
        const action = function(itemsPerRow) {            
            getActiveTags();
            compareTagsWithItems();
            newGrid(itemsPerRow);
        };

/*
        const resetHandlers = function(e) {
            for (let i = 0; i < tag.length; i++) {
                
                if (tag[i].checked === true) {
                   tag[i].checked = false;
                }
                
                
            }
            
            getActiveTags();
            compareTagsWithItems();
            newGrid();
            
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };
*/
        
        
        setDefaultGrid();
        //action();
        
        mediaQueryList.addEventListener('change', event => {
            event.matches ? action(2) : action(3);
        })
        
        mediaQueryList.matches ? action(2) : action(3);
        
        for (let i = 0; i < tag.length; i ++) {
            tag[i].onchange = ()=> {
                mediaQueryList.matches ? action(2) : action(3);
            }
        }
        
        //reset.addEventListener('click', resetHandlers);
        
    };
    
    filters ? init() : false;
    
}, false);

(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Roboto:400,500,700,900:latin-ext', 'Inter:400:latin-ext']
        }
    });
})();


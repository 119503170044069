document.addEventListener('DOMContentLoaded',function() {
    
    const el = document.getElementsByClassName('js-toggle');
    
    const init = function() {

        const action = function(e) {
            
            const parent = e.currentTarget.parentNode;
            
            if (parent.classList.contains('is-visible')) {
                
                parent.classList.remove('is-visible');
                parent.classList.remove('is-active');

            } else {
                
                parent.classList.add('is-visible');

                setTimeout(function() {
                    parent.classList.add('is-active');
                }, 100);
            }
            
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };
        
        for (let i = 0; i < el.length; i++) {

            el[i].addEventListener('click', action);
        }
    };
    
    el.length > 0 ? init() : false;


}, false)
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
 
document.addEventListener('DOMContentLoaded', ()=> {

    const input = document.querySelectorAll(".js-intl");

    const init = ()=> {
        for (let i = 0; i < input.length; i++) {
            intlTelInput(input[i], {
                initialCountry: 'pl',
                separateDialCode: 1
            });
        }
    }
    
    input.length > 0 ? init() : false;
    
}, false);
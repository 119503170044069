import Swiper, { EffectCards, Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/effect-cards/effect-cards.scss';

const Flickity = require('flickity');
const utils = require('fizzy-ui-utils');

document.addEventListener('DOMContentLoaded',function() {
    
    const disablePointerEvents = function(container, flkty) {
        
        let pause; 
        let cell = container.getElementsByClassName('carousel-cell');
        
        flkty.on('dragStart', function() {            
            
            for (let i = 0; i < cell.length; i++) {
                cell[i].getElementsByTagName('a')[0].style.pointerEvents = 'none';
            }
        });
        
        flkty.on('dragEnd', function() {

            setTimeout(function() {
                
                for (let i = 0; i < cell.length; i++) {
                    cell[i].getElementsByTagName('a')[0].style.pointerEvents = 'all';
                } 

            }, 100);

        });
    };

    if (typeof Flickity === 'function') {

      var proto = Flickity.prototype;
    
      proto.watchCSS = function () {
        var watchOption = this.options.watchCSS;
        if (!watchOption) {
          return;
        }
    
        var afterContent = getComputedStyle(this.element, ':after').content;
        // activate if :after { content: 'flickity' }
        if (afterContent.indexOf('flickity') != -1) {
          // ---modification start---
          // check for watchCSS options changes
          if (typeof watchOption === 'object') {
            this.watchCSSOptionsChange(afterContent);
          }
          // ---modification end---
          this.activate();
        } else {
          this.deactivate();
        }
      };
    
      proto.watchCSSOptionsChange = function (afterContent) {
        // store the current breakpoint identifier
        if (!this._currentOptionsIdentifier) {
          this._currentOptionsIdentifier = '';
        }
        // trim flickity. and surrounding quotes from the new breakpoint identifier
        var identifier = afterContent.substring(0, afterContent.length - 1).substring(10);
    
        // check for breakpoint change
        if (this._currentOptionsIdentifier !== identifier) {
    
          // if the original options have been cloned apply them to reset
          if (typeof this.options._flickityInitialOptions === 'object') {
    
            this.options = this.options._flickityInitialOptions;
            this._currentOptionsIdentifier = '';
          }
    
          // check if the new breakpoint options exist
          if (typeof this.options.watchCSS[identifier] === 'object') {
    
            // clone the original options so we can reset on breakpoint change
            this.options._flickityInitialOptions = JSON.parse(JSON.stringify(this.options));
    
            // apply the new options
            var newOptions = this.options.watchCSS[identifier];
    
            for (var key in newOptions) {
              if (newOptions.hasOwnProperty(key)) {
                this.options[key] = newOptions[key];
              }
            }
    
            // update the identifer so we can skip if there's no change in breakpoint
            this._currentOptionsIdentifier = identifier;
          }
        }
      }
    }
    
    const controls = function(container, arg) {
        
        // Arrows
        
        container.nextElementSibling.classList.add('is-visible');
              
        const prevArrow = container.nextElementSibling.getElementsByClassName('js-previous')[0];
        const nextArrow = container.nextElementSibling.getElementsByClassName('js-next')[0];
        
        prevArrow.addEventListener( 'click', function() {
            arg.previous();
        });
        
        nextArrow.addEventListener( 'click', function() {
            arg.next();
        });
    };

    const selectorMatches = function(el, selector) {
    	var p = Element.prototype;
    	var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function(s) {
    		return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
    	};
    	return f.call(el, selector);
    };
    
    window.casesCarousel = function() {
        
        const cases = document.querySelectorAll('.js-case');
        let teamflkty = new Array();

        const options = {
            wrapAround: 0,
            cellAlign: 'left',
            groupCells: true
        }
        
        const init = function() {
            for (let i = 0; i < cases.length; i++) {
                teamflkty[i] = new Flickity( cases[i], options );
                
                if (teamflkty[i].cells.length > 2) {
                    controls(cases[i], teamflkty[i]);
                }                
            }
        }        
        
        init();
    };
    
    window.caseTeamCarousel = function() {
        
        const caseteam = document.querySelectorAll('.js-caseteam');
        let teamflkty = new Array(),
            mediaQuery = '(max-width: 768px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            mediaQuerySmall = '(max-width: 500px)',
            mediaQueryListSmall = window.matchMedia(mediaQuerySmall);

        const check_cells_medium = () => {
            for (let i = 0; i < caseteam.length; i++) {
                if (caseteam[i].querySelectorAll('.carousel-cell').length <= 3) {
                    caseteam[i].classList.add('no-dots');
                }
            }
        }
        
        const check_cells_small = () => {
            for (let i = 0; i < caseteam.length; i++) {
                if (caseteam[i].querySelectorAll('.carousel-cell').length > 2) {
                    caseteam[i].classList.remove('no-dots');
                }
            }
        }
    
        const desktop = () => {
            if (teamflkty.length > 0) { 
                for (let i = 0; i < caseteam.length; i++) {
                    teamflkty[i].destroy()
                }
            }
        }

        const mobile = () => {
            for (let i = 0; i < caseteam.length; i++) {
                teamflkty[i] = new Flickity( caseteam[i], {
                    wrapAround: 0,
                    cellAlign: 'center',
                    groupCells: true,
                });
                
                check_cells_medium();
            }
        }

        mediaQueryList.addEventListener('change', event => {
            event.matches ? mobile() : desktop();
        })
        
        mediaQueryListSmall.addEventListener('change', event => {
            event.matches ? check_cells_small() : check_cells_medium();
        })
        
        mediaQueryList.matches ? mobile() : desktop();  
        mediaQueryListSmall.matches ? check_cells_small() : false;
    };
    
    window.clientsCarousel = function() {
        
        const clients = document.getElementById('clients');
        let teamflkty;
        
        const desktop = () => {
            teamflkty = new Flickity( clients, {
                wrapAround: true,
                cellAlign: 'left',
                freeScroll: false,
                freeScrollFriction: 0.03,
            });
            controls(clients, teamflkty);
        }        
        
        const mobile = () => {
            teamflkty = new Flickity( clients, {
                wrapAround: true,
                cellAlign: 'left',
                freeScroll: false,
                freeScrollFriction: 0.03,
                groupCells: true
            });
        }
        
        let mediaQuery = '(max-width: 768px)';
        let mediaQueryList = window.matchMedia(mediaQuery);
        
        mediaQueryList.addEventListener('change', event => {
            event.matches ? mobile() : desktop();
        })
        
        mediaQueryList.matches ? mobile() : desktop();
    };
    
    window.partnersCarousel = () => {
        const swiper_desc = new Swiper(document.querySelector('.js-partnersDesc'), {
            modules: [Thumbs, Pagination],
            allowTouchMove: true,
            autoHeight: true,
            breakpoints: {
                769: {
                    allowTouchMove: false,
                },
            },
            speed: 500,
            loop: 1,
            on: {
                init: function() {
                    window.partners_cooperation();
                }
            },
            pagination: {
                el: '#partners .swiper-pagination',
                clickable: 'true',
            },
            watchSlidesProgress: true,
	    });

        const swiper_logotypes = new Swiper(document.querySelector('.js-partnersLogotypes'), {
            modules: [EffectCards, Thumbs, Navigation],
            effect: "cards",
            cardsEffect: {
                perSlideOffset: 4,
                slideShadows: 0,
            },
            loop: 1,
            speed: 500,
            thumbs: {
                swiper: swiper_desc,
            },
            navigation: {
                nextEl: document.getElementById('partners').querySelector('.swiper-button-next'),
                prevEl: document.getElementById('partners').querySelector('.swiper-button-prev'),
            },
	    });   
    }

    window.projectsCarousel = function() {
        
        const projects = document.querySelectorAll('.js-projects');
        let flkty = new Array();
        
        
        for (let i = 0; i < projects.length; i++) {
            flkty[i] = new Flickity( projects[i], {
                wrapAround: true,
                cellAlign: 'left',
                freeScroll: true,
                freeScrollFriction: 0.03,
            });
            
            controls(projects[i], flkty[i]);
            flkty[i].resize();
        }

       /*
 disablePointerEvents(mechanics, mechanicsflkty);
        
*/
    };

    window.teamCarousel = function() {
        
        const team = document.getElementById('team');

        const teamflkty = new Flickity( team, {
            wrapAround: true,
            cellAlign: 'left',
            freeScroll: true,
            freeScrollFriction: 0.03,
        });
    
        controls(team, teamflkty);
                    
       /*
 disablePointerEvents(mechanics, mechanicsflkty);
        
*/
    };



}, false)
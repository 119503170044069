document.addEventListener('DOMContentLoaded', () => {
    window.homeVideo = () => {
        const videoPlayer = document.getElementById("video"),
              mediaQuery = '(max-width: 800px)',
              mediaQueryList = window.matchMedia(mediaQuery);
              
        const bigVid = () => {
            videoPlayer.src = videoPlayer.dataset.big;
            videoPlayer.load();
        }
        
        const smallVid = () => {
            videoPlayer.src = videoPlayer.dataset.small;
            videoPlayer.load();
        }
        
        mediaQueryList.matches ? smallVid() : bigVid();
        mediaQueryList.addEventListener('change', event => {
            event.matches ? smallVid() : bigVid();
        })
    }
}, false);
/*
 * Copyright 2015 AlterPage Sp. z o.o. licence, Version 1.0;
 * all rights reserved
 *
 * AlterPage Sp. z o.o.
 * ul. Alzacka 16
 * 03-927 Warsaw, Poland
 * kontakt@alterpage.pl
 * http://alterpage.pl
 *
 * developer: Natalia.
 */

(function() {
    function Cookies() {
        var $cookies;
        var $accept;

        /**
         * Initialize
         *
         * @returns {undefined}
         */
        function init() {

            $cookies = $('#cookies');
            $accept = $('#accept-cookies');

            checkCookie();
            bind();
        }

        /**
         * Bind all events
         *
         * @returns {undefined}
         */
        function bind() {

            $accept
                .off('click')
                .on('click', function (e) {
                    e.preventDefault();
                    document.cookie = "cookies=1; expires=Fri, 31 Dec 2099 23:59:59 GMT; path=/";
                    $cookies.removeClass('open');
                });
        }

        /**
         * Chceck if cookie exist
         *
         * @returns {undefined}
         */
        function checkCookie() {
            if (getCookie('cookies') !== '1')
                $cookies.addClass('open');
        }

        /**
         * Get cookie by name
         *
         * @param {type} cname
         * @returns {String}
         */
        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ')
                    c = c.substring(1);
                if (c.indexOf(name) === 0)
                    return c.substring(name.length, c.length);
            }
            return "";
        }

        init();
    }
    var cookies = new Cookies();
})();

import validate from 'validate.js';
import Toastify from 'toastify-js';
import { AsYouType } from 'libphonenumber-js';
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/max'
import "toastify-js/src/toastify.css"

document.addEventListener('DOMContentLoaded', ()=> {

    const init = function(el, constraints) {
        const form = el;

        form.addEventListener("submit", (ev)=> {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        const inputs = form.querySelectorAll("input, textarea, select");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", (ev)=> {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
                console.clear();
                console.log(errors);
                showErrorsForInput(ev.currentTarget, errors[ev.currentTarget.name])
            });
        }

        function handleFormSubmit(form, input) {
            const errors = validate(form, constraints, { fullMessages: false });
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            } else {
            }
        };

        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            _.each(form.querySelectorAll("[required]"), (input)=> {
                showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            const formGroup = closestParent(input.parentNode, "form-group"),
                  messages = formGroup.querySelector(".js-message");
                console.log(formGroup);
            resetFormGroup(formGroup);

            if (errors) {
                formGroup.classList.add("has-error");

                if (formGroup.parentNode.classList.contains('form-children'))  {
                    document.getElementById(formGroup.parentNode.dataset.id).parentNode.parentNode.classList.add('has-error');
                }

                _.each(errors, function(error) {
                    if (messages) {
                        addError(messages, error);
                    }
                });
            } else {
                formGroup.classList.add("has-success");

                if (formGroup.parentNode.classList.contains('form-children'))  {
                    document.getElementById(formGroup.parentNode.dataset.id).parentNode.parentNode.classList.remove('has-error');
                }
            }
        };

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };

        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
                el.parentNode.removeChild(el);
            });
        }

        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            const block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function startAjax() {
            form.querySelector('button').classList.add('loading');
        }

        function finishAjax() {
            form.querySelector('button').classList.remove('loading');
        }

        const formToJSON = (elements) =>
          [].reduce.call(
            elements,
            (data, element) => {
                if (element.type === 'radio') {
                    if (element.checked) {
                        data[element.name] = element.value;
                    }
                } else {
                    if (element.name == 'phone') {
                        data[element.name] = element.value.replaceAll(" ", "");
                    } else {
                        data[element.name] = element.value;
                    }
                }
              return data;
            },
            {},
        );

        function submitForm() {
            /*            Toastify({
                            text: document.querySelector('.js-caseContactThanksForm').innerHTML,
                            duration: 5000,
                            className: "info",
                            gravity: "top",
                            position: "left",
                            stopOnFocus: true,
                        }).showToast();

                        form.reset();
                        finishAjax();

                        const formDa = new FormData(form);

                        for (const [key, value] of formData) {
                            console.log(`${key}: ${value}\n`);
                        }

              */

                        let xmlHttp = new XMLHttpRequest(),
                            url = form.getAttribute('action');

                        xmlHttp.onload = function() {
                            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {

                                Toastify({
                                    text: document.querySelector('.js-caseContactThanksForm').innerHTML,
                                    duration: 5000,
                                    className: "info",
                                    gravity: "top",
                                    position: "left",
                                    stopOnFocus: true,
                                }).showToast();

                                form.reset();

                            } else {
                            }
                           finishAjax();
                        };


                        const postData = new FormData(form);
                        postData.append('email2', '');
                        if(!postData.get('email')) {
                            postData.append('email', 'brak@brak.test');
                        }

                        xmlHttp.open('post', url, true);
                        // xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                        xmlHttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                        xmlHttp.send(postData);

        }

        function showSuccess() {
            startAjax();
            submitForm();
        }

        // Custom Validate for telephone
        validate.validators.telCheck = function(value, options, key, attributes) {
            if (value) {
                let phoneNumber = parseMin(value, 'PL');

                if (phoneNumber.isValid()) {
                    return;
                } else {
                    return 'Wprowadź poprawny numer';
                }
            }
        };
    };

    const telFormat = function() {
        let inputs = document.getElementsByTagName('input');

        for(let i = 0; i < inputs.length; i++) {
            if(inputs[i].type.toLowerCase() == 'tel') {
               inputs[i].addEventListener("keyup", event => {
                    let val_old = inputs[i].value,
                        val_new = new AsYouType('PL').input(inputs[i].value);
                    inputs[i].value = val_new;
                });
            }
        }
    }();


    // Init

    const caseContactForm = ()=> {
        const constraints = {
            gdpr: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },
            phone: {
                presence: { message: "Pole nie może być puste" },
                telCheck: "Podaj poprawny numer"
            },
        };

        init(document.querySelector('.js-caseContactForm'), constraints);
    };
    
    const contactContactForm = ()=> {
        const constraints = {
            gdpr: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },
            email: {
                presence: { message: "Pole nie może być puste" },
                email: { message: "To nie jest poprawny adres" }
            },
            phone: {
                presence: { message: "Pole nie może być puste" },
                telCheck: "Podaj poprawny numer"
            },
        };

        init(document.querySelector('.js-contactContactForm'), constraints);
    };

    const footerContactForm = ()=> {
        const constraints = {
            gdpr: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },
            email: {
                presence: { message: "Pole nie może być puste" },
                email: { message: "To nie jest poprawny adres" }
            },
            phone: {
                presence: { message: "Pole nie może być puste" },
                telCheck: "Podaj poprawny numer"
            },
        };

        init(document.querySelector('.js-footerContactForm'), constraints);
    };

    document.querySelector('.js-caseContactForm') ? caseContactForm() : false;
    document.querySelector('.js-contactContactForm') ? contactContactForm() : false;    
    document.querySelector('.js-footerContactForm') ? footerContactForm() : false;


}, false);
